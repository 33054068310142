
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'MobileSortButton',
  setup(_, { emit }) {
    function toggleSort() {
      emit('toggleSort', true);
    }
    return { toggleSort };
  },
});
